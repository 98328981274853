// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-dataprivacy-jsx": () => import("./../../../src/pages/dataprivacy.jsx" /* webpackChunkName: "component---src-pages-dataprivacy-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-prototype-jsx": () => import("./../../../src/pages/prototype.jsx" /* webpackChunkName: "component---src-pages-prototype-jsx" */),
  "component---src-pages-survey-jsx": () => import("./../../../src/pages/survey.jsx" /* webpackChunkName: "component---src-pages-survey-jsx" */)
}

